body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content {
    margin: 20px 0 80px;
}

.nav-home-icon {
    position: relative;
    top: 5px;
}

@media only screen and (max-width: 600px) {
    .hero-image {
        width: 100%;
    }
}

@media only screen and (min-width: 600px) {
    .hero-image {
        width: 70%;
    }
}

.timeline-title {
    font-size: larger;
}

.timeline-info {
    font-size: inherit;
}

#treeWrapper {
    width: 400px;
    height: 600px;
}